import React from "react"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import { injectIntl } from "gatsby-plugin-react-intl"
import MobilityData from "../../../content/solutions/mobility/index.json"
import {Container} from "react-bootstrap";
import SimpleBlock from "../../../components/misc/simple-block";
import HeroFeatures from "../../../components/heroes/hero-features";
import MultiColBlock from "../../../components/misc/multi-col-block";
import DemoTrial from "../../../components/ctas/demo-trial";
import TextBlock from "../../../components/misc/text-block";
import Testimonial from "../../../components/misc/testimonial";

const SolutionsMobilityPage = ({ intl, location }) => (
    <Layout>
        <Seo lang={intl.locale} title={MobilityData[intl.locale].title} description={MobilityData[intl.locale].text} pathname={location.pathname} />
        <HeroFeatures
          theme={`primary`}
          content={MobilityData[intl.locale]}
        />
        <Container>
            <TextBlock content={MobilityData[intl.locale].intro} />
        </Container>
        <Container>
            {MobilityData[intl.locale].blocks.map((item, i) => {

                return (
                    <SimpleBlock key={i} content={item} />
                )

            })}
        </Container>
        <div className={`bg-light`}>
            <Container>
                <Testimonial content={MobilityData[intl.locale].testimonial}/>
            </Container>
        </div>
        <div className={`bg-white`}>
            <Container>
                <MultiColBlock content={MobilityData[intl.locale].callout} />
            </Container>
        </div>
        <DemoTrial />
    </Layout>
)

export default injectIntl(SolutionsMobilityPage)
